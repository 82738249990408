.burger {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    z-index: 999999;
  }
  
  .burger.open {
    opacity: 1;
    visibility: visible;
  }
.close-btn {
    border: none;
}
.close-btn:hover {
    cursor: pointer;
}

.cross-icon {
position: relative;
width: 32px;
height: 32px;
background-color: black;

&::before,
&::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #ffffff;  // Couleur de la croix (blanche dans cet exemple)
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

&::before {
    transform: rotate(45deg);
}

&::after {
    transform: rotate(-45deg);
}
}