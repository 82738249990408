.actu {
    background-color: #ffffff;
}

.img-content-actu {
    width: 50%;
    padding: 1%;
}

.hidden-img {
    display: none;
}

.a_discord {
    color: blue !important;
    padding: none !important;
    display: inline !important;
    text-decoration: none !important;
}

.actu-content {
    color: black;
    margin: 0 10% 2% 10%;
    background-color: #ccc; /* Couleur de fond */
    padding: 20px; /* Marge intérieure pour l'espace autour du contenu */
    border: 1px solid #c4c4c4; /* Bordure */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Ombre */
    border-radius: 5px; 
}

.p-content-actu {
    width: 100%;
    padding-left: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ul-content-actu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
}

a {
    text-decoration: none;
}