.navmap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1%;
}

.btn {
    margin: 1%;
    padding: 0.5%;
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
}

.btn-primary {
    background-color: #323335;
    color: #fafafa;
    
}

@media screen and (max-width: 450px) {
    .navmap {
        flex-direction: column;
        padding-top: 10%;
    }
}

@media screen and (max-width: 900px) {
    .navmap {
        display: none;
    }
}