.search-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #191B1C;
}

.search-input {
    background-color: #F4F4F4;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 5px;
    margin: 1%;
    width: 50%;
}

li {
float: left;
}

li a {
display: block;
color: white;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}

li a:hover:not(.active) {
background-color: #111;
}

.active {
background-color: #04AA6D;
}