@import './Cards.scss';

body {
    font-family: 'comic sans MS', 'arial';
}

.container {
    // margin-left: 10%;
    // margin-right: 10%;
    padding: 1px 16px;
    height: 100%;
}

.d-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 573px;
    height: 373px;
    border: 1px solid;
    border-radius: 10px;
    padding: 1%;
    margin: 1%;
    background: linear-gradient(180deg, rgba(13, 0, 167, 0.75) 0%, rgba(2, 0, 28, 0.75) 100%);
    color: white;
}

hr {
    width: 100%;
}

.p-entity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.h4-entity {
    margin-left: 1%;
}

.cards.p-entity {
    display: flex;
}

.border-v-entity {
    border: none;
    border-right: 1px solid hsla(200, 10%, 50%, 100);
    padding: 1%;
}

.fa-size {
    width: 32px;
    height: 32px;
}

.t-entity {
    border-bottom: 1px solid;
}

.ma {
    margin: auto;
}

.imgEvidence {
    width: 52px;
    height: 52px;
}