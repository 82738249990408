/* ---------------- Navbar and burger menu ------------------ */
nav {
    height: 5em;
    position: absolute;
    padding: 1em;
}

.burger-menu {
    height: 100%;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
}

.burger-bar {
    width: 2.5em;
    height: 0.3em;
    background-color: #ffffff;
    border-radius: 0.5em;
    position: relative;
    z-index: 999999999;
}

.menu {
    width: 10%;
    height: 100%;
    background-color: #4b4b4b;
    position: fixed;
    top: 0;
    z-index: -1;
}

.navbar-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.navbar-ul a {
    display: block;
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 2em;
}

.navbar-ul a.active {
    background-color: #04AA6D;
    color: white;
}

.navbar-ul a:hover:not(.active) {
    background-color: #555;
    color: white;
}

.navbar {
    position: absolute;
    margin: 1%;
    right: 0;
}

.menu-icon {
    position: relative;
    width: 32px;
    height: 32px;
  
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 80%;
      background-color: #ffffff;  // Couleur de l'icône de menu (blanche dans cet exemple)
      top: 50%;
      left: 10%;
      transition: transform 0.3s ease-in-out;
    }
  
    &::before {
      transform: translateY(-6px);
    }
  
    &::after {
      transform: translateY(6px);
    }
  
    &.open {
      &::before {
        transform: rotate(45deg) translateY(0);
      }
  
      &::after {
        transform: rotate(-45deg) translateY(0);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .nav {
      display: none;
    }
  }