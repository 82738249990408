.random3 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerObject {
    display: flex;
}
.containerPlayers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2%;
}

.imgObject {
    width: auto;
    height: auto;
    max-width: 350px;
    max-height: 350px;
}

@media screen and (max-width: 450px) {
    .containerObject {
        flex-direction: column;
    }
}