@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);

$border-radius-size: 14px;
// $primary: linear-gradient(180deg, #0D00A7 0%, #02001C 100%);
$primary: #191B1C;

*, *:before, *:after {
  box-sizing: border-box;
}

.slide-container {
  margin: auto;
  width: 600px;
  text-align: center;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  
  &:focus {
    outline: 0;
  }
}

.clash-card {
  // background: white;
  color: white;
  width: 400px;
  height: 500px;
  display: inline-block;
  margin: auto;
  border-radius: $border-radius-size + 5;
  position: relative;
  text-align: center;
  box-shadow: -1px 15px 30px -12px rgb(51, 51, 51);
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 9999;
}

.clash-card__level {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
}

.clash-card__level--barbarian {
  color: $primary;
}

.clash-card__unit-name {
  font-size: 26px;
  color: white;
  font-weight: 900;
  margin-bottom: 5px;
}

.clash-card__unit-description {
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;  
}

.clash-card__unit-stats--barbarian {
  background: $primary;
  
  .one-third {
     border-right: 1px solid #e9e9e9;
  }
}

.clash-card__unit-stats {
  
  color: white;
  font-weight: 700;

  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  
  border-bottom-left-radius: $border-radius-size;
  border-bottom-right-radius: $border-radius-size;
  
  .one-third {
    width: 25%;
    float: left;
    padding: 20px 15px;
  }
  
  sup {
    position: absolute;
    bottom: 4px; 
    font-size: 45%;
    margin-left: 2px;
  }
  
  .stat {
    position: relative;
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
  }

  .no-border {
    border-right: none;
  }
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

@media screen and (max-width: 450px) {
  .clash-card {
    width: 100%;
  }
}
// .slick-prev {
//   left: 100px;
//   z-index: 999;
// }

// .slick-next {
//   right: 100px;
//   z-index: 999;
// }