// $primary: linear-gradient(180deg, #0D00A7 0%, #02001C 100%);
$primary: #303131;

.Container-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 5%;
    margin: auto;
    align-items: center;
    max-width: 1050px;
}
.item-content {
    flex: 0 0 33.3333%;
    color: white;
    width: 350px;
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 2%;
}

.item-content::-webkit-scrollbar { 
    display: none; 
}

.item-tier {
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    // border-bottom: 1px solid white;
    // max-height: 350px;
    height: auto;
    width: auto;
}
.no-border {
        border-right: none;
      }
.imgItem {
    width: auto;
    height: 300px;
}

p {
    border-top: 1px solid #fff;
}

h3 {
    border-bottom: 1px solid #fff;
}

@media screen and (max-width: 977px) {
    .Container-items {
        flex-direction: column;
    }

    .item-content {
        flex: none;
        color: white;
        padding: 1%;
        // margin-left: 1%;
    }
}